/*
** Домашняя страница
*/

//
// Карусель (Orbit)
//
.orbit-info {
  background: rgba(255, 255, 255, 0.55);
  border-color: #036;
  color: #036;
  font-weight: 700;
  line-height: 1.2;
  max-width: 60%;
  min-height: 20%;
  max-height: 70%;
  padding: 0.38rem 0.65rem;
  position: absolute;
  top: 1rem;
  left: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;

  &.right {
    left: initial;
    right: 3rem;
  }

  &.bottom {
    top: initial;
    bottom: 2rem;

    @include breakpoint(medium only) {
      bottom: 2rem;
    }

    @include breakpoint(large up) {
      bottom: 3rem;
    }
  }

  a {
    color: inherit;
    display: grid;
    grid-gap: 0.5rem;

    &:hover {
      color: inherit;
    }
  }

  @include breakpoint(medium only) {
    top: 2rem;
    padding: 0.46rem 0.8rem;
  }

  @include breakpoint(large up) {
    top: 3rem;
    padding: 0.55rem 1rem;
  }

  header {
    font-size: small;

    @include breakpoint(medium only) {
      font-size: large;
    }

    @include breakpoint(large up) {
      font-size: x-large;
    }
  }

  p {
    padding: 0;
    margin-bottom: 0;
    font-size: x-small;

    @include breakpoint(medium only) {
      font-size: small;
    }

    @include breakpoint(large up) {
      font-size: inherit;
    }
  }

  //   .button {
  //     display: none; /* уберем кнопку на смартфонах */
  //     font-size: small;
  //     font-weight: 600;
  //     border-color: inherit;
  //     color: inherit;
  //     padding: 0.65em 3em;

  //     &:hover {
  //       color: inherit;
  //       border-color: inherit;
  //     }

  //     @include breakpoint(medium only) {
  //       display: inline-block;
  //       padding: 0.75em 3em;
  //     }

  //     @include breakpoint(large up) {
  //       display: inline-block;
  //       padding: 0.85em 3em;
  //     }
  //   }
}

// Высота картинки слайдера
// Должна быть скорректирована если изменится в шаблоне
// home/jinja2/home/includes/carousel_item.html
.hp-orbit-img {
  min-height: 432px;
}

// Подпись к картинке в слайдере
.orbit-caption {
  padding: 0.4rem;
  font-size: x-small;

  @include breakpoint(medium only) {
    font-size: smaller;
  }

  @include breakpoint(large up) {
    font-size: small;
  }
}

// Заголовки разделов на домашней странице

.hp-title {
  color: $dark-blue;
  overflow: hidden;
  padding-left: 3rem;

  &::before,
  &::after {
    content: "";

    /* Свойства линий, выравнивание по центру по вертикали */
    display: inline-block;
    vertical-align: middle;

    /* Ширина - 100% и указываем толщину линии */
    width: 100%;
    height: 2px;

    /* Цвет линии */
    background-color: $dark-blue;

    /* Возможность для пседоэлементов изменить позицию линии */
    position: relative;
  }

  &::before {
    /* Ставим левую линию слева от текста, смещая ее на 100% влево */
    margin-left: -100%;

    /* Отступ линии от заголовка */
    left: -1rem;
  }

  &::after {
    /* Ставим правую линию справа от текста, смещая ее на 100% вправо */
    margin-right: -100%;

    /* Отступ линии от заголовка */
    right: -1rem;
  }
}

.card-news {
  h3 {
    a.news-link {
      color: $hp-news-hdr;
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        color: $dark-blue;
      }
    }

    @include breakpoint(medium only) {
      font-size: large;
    }

    @include breakpoint(large up) {
      font-size: x-large;
    }
  }

  p {
    color: $text-dark-gray;

    @include breakpoint(medium only) {
      font-size: smaller;
    }
  }

  time {
    font-style: italic;
    vertical-align: middle;
  }
}

.card-advert {
  h3 {
    @include breakpoint(medium only) {
      font-size: large;
    }

    @include breakpoint(large up) {
      font-size: x-large;
    }
  }

  p {
    color: $text-dark-gray;

    @include breakpoint(medium only) {
      font-size: smaller;
    }
  }

  .card-divider {
    padding: 0.3rem 1rem;
    background-color: lightblue;
    font-style: italic;
  }
}

.hp-btn {
  border-radius: 0.4rem;
  font-weight: 700;

  &:hover {
    background-color: $dark-blue;
  }
}
