.post-news {
  .thumbnail {
    img {
      @include breakpoint(small only) {
        width: 480px;
      }

      @include breakpoint(medium only) {
        width: 160px;
      }
    }
  }

  h2 {
    @include breakpoint(medium only) {
      font-size: x-large;
    }

    @include breakpoint(large up) {
      font-size: xx-large;
    }
  }
}
