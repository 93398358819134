/*
** Управление координатной сеткой сайта
*/

.grid-container {
  padding: 0;
  margin: 0;

  @include breakpoint(medium up) {
    margin-left: auto;
    margin-right: auto;
  }
}

.site-wrapper {
  padding: 0.5rem 0;
}
