.download {
  align-items: center;
  display: flex;

  a {
    align-items: center;
    color: $dark-blue;
    display: flex;
    font-weight: 700;
    padding-right: 1rem;
  }

  i {
    margin-right: 0.5rem;
  }

  .filesize {
    font-style: italic;
  }
}

.publ-hdr {
  h1,
  .subtitle {
    //    font-family: Oswald, "Helvetica Neue Bold Condensed", "Helvetica Condensed", "Roboto Condensed", Arial, sans-serif;
    line-height: 0.8;
    font-size: 700;
  }

  h1 {
    @include breakpoint(medium only) {
      font-size: xx-large;
    }
  }

  .subtitle {
    margin-bottom: 0.5em;
    font-size: 1.4rem;

    @include breakpoint(large up) {
      font-size: 1.7rem;
    }
  }

  .with-subtitle {
    margin-bottom: 0.5em;
  }
}

.toc-list,
.toc-list ul {
  list-style-type: none;
  margin-left: 0;
}

.toc-list {
  padding: 0;

  ul {
    padding-inline-start: 1ch;
  }

  li {
    div {
      display: grid;
      grid-template-columns: auto max-content;
      align-items: end;

      .title-dots {
        position: relative;
        overflow: hidden;

        &::after {
          --d: " . . . . . . . . . . . . . . . . . . . . . . . . . . . ";

          position: absolute;
          padding-left: 0.25ch;
          content: var(--d) var(--d) var(--d) var(--d) var(--d) var(--d)
            var(--d);
          text-align: right;
        }
      }

      .title {
        position: relative;
        overflow: hidden;
      }

      .page {
        min-width: 3ch;
        font-variant-numeric: tabular-nums;
        text-align: right;
      }
    }
  }
}
