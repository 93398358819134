.emp-hdr {
  h1 {
    @include breakpoint(medium only) {
      font-size: x-large;
    }

    @include breakpoint(large up) {
      font-size: xx-large;
    }
  }

  .lbl {
    font-weight: 600;
  }
}

.emp-filter {
  background-color: #23406c;
  padding-top: 0.85rem;

  .label-div {
    align-items: center;
    display: flex;
    margin-top: -0.85rem;
  }

  label {
    color: $white;
    font-weight: 600;
  }

  .button {
    width: 100%;
  }
}

.emp-tbl {
  margin-top: 1rem;

  tr {
    font-weight: 600;
    color: $dark-blue;

    &:hover {
      color: $white;
      background-color: #23406c;
    }

    a {
      color: inherit;
    }
  }
}

.leader {
  a { // stylelint-disable-line
    color: $dark-blue;
    transition: .3s all ease;

    &:hover {
      text-decoration: underline;
    }
  }

  h3 {
    font-family: Raleway, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: x-large;
    font-weight: 600;
  }
}
