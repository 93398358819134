/*
** Подвал сайта (footer)
*/

footer {
  background: $footer-bg;
  color: #fff;
  padding: 0;
  width: 100%;

  p {
    background: $footer-bg;
  }

  a {
    color: #fff;

    &:hover {
      color: lightgray;
      font-weight: 700;
    }
  }

  nav {
    padding: 0.3em 0 0.1em;
    font-size: small;

    ul {
      margin: 0 0 .6rem .8rem;
    }

    li {
      list-style: none;
    }
  }

  .body {
    border-top: 1px solid #74797d;
    border-bottom: 1px solid #74797d;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .snippet-block {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .snippet {
      padding-right: .7rem;
    }
  }

  .brand {
    display: flex;
    font-size: small;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0;

    a {
      color: #e2cc42;

      &:hover {
        color: #b6a20c;
      }
    }

    .logo img {
      max-height: 64px;
    }

  }

  .hdr {
    font-weight: 700;
    text-decoration: underline;
  }

  .contacts {
    padding-right: .7rem;

    ul {
      list-style: none;

      > li {
        clear: left;
        padding: .15rem 0;
      }
    }
  }

  .last {
    font-size: x-small;

    p {
      margin-bottom: 0;
      background-color: $footer-bg;
    }
  }

  .tvm {
    font-weight: 700;
    white-space: nowrap;
  }
}
