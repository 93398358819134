/*
** Элементы в полях StreamField и RichText
*/

@mixin img-float-left {
  float: left;
  margin: 0 1em 1em 0;
}


// RichText
.richtext-image {
  display: block;
}

//
// Streamfields
//

.sf-aligned-img {
  display: table;
  height: auto;

  img {
    width: 100%;
  }

  figcaption {
    background-color: $very-dark-gray;
    caption-side: bottom;
    color: white;
    display: table-caption;
    font-size: small;
    padding-left: .2rem;

    .attr {
      color: coral;
      display: block;
      font-size: smaller;
      padding-right: .2rem;
      text-align: right;
    }
  }
}

.richtext-image, .sf-aligned-img {
  &.left {
    @include img-float-left;

    max-width: 100%;
  }

  &.right {
    float: right;
    margin: 0 0 1em 1em;
  }

  &.full-width {
    width: 100%;
    height: auto;
  }

  &.half-width {
    @include img-float-left;

    width: 50%;
  }
}

//
// Details Block
//
.sf-details {
  > summary {
    padding: 5px;
    background-color: #eee;
    color: #333;
    border: 1px #ccc solid;
    cursor: pointer;
  }

  > div {
    border: 1px #ccc solid;
    padding: 10px;
  }

  &[open] > summary {
    color:#eee;
    background-color:#333;
  }
}

//
// HeadingBlock
//
.sf-heading {
  p, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }

  p {
    line-height: 1.1;
  }

  .subheader {
    margin-bottom: 1rem;
  }
}

//
// PullQoute
//
.sf-pullquote {
  position: relative;
  padding-top: .7em;
  padding-bottom: .5rem;

  blockquote {
    border-left: none;
    color: gray;
    font-size: 1.1em;
    font-style: italic;
    line-height: 1.3 !important;
    margin: 0;
    padding: 0;
    position: relative;
    text-shadow: 0 1px white;
    z-index: 600;

    * {
      box-sizing: border-box;
      color: #4f4f4f
    }

    p {
      line-height: 1.2 !important;

      &:first-child {
        text-indent: 3em;

        &::before {
          content: '\201C';
          color: #81bedb;
          font-size: 7.5em;
          font-weight: 700;
          opacity: .3;
          position: absolute;
          top: -.3em;
          left: -.55em;
          text-shadow: none;
          z-index: -300;
        }
      }
    }

    cite {
      color: gray;
      display: block;
      font-size: .8em;

      &::before {
        content: none;
      }

      span {
        display: block;
      }

      .author {
        color: #5e5e5e;
        font-size: 1em;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-shadow: 0 1px white;
      }
    }
  }

  img {
    border: 3px solid #9CC1D3;
    border-radius: 50%;
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: -.2em;
    left: .6em;
  }

  .container {
    padding-left: 160px;
  }

  &.right {
    .container {
      padding-left: 0;
      padding-right: 160px;
    }

    img {
      left: auto;
      right: .6em;
    }

    cite {
      text-align: right;
    }
  }

  &.no-img .container {
    padding-left: 3em;
  }
}
