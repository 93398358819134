.fiv-size-md {
  font-size: 2.25em;
}

.fiv-size-lg {
  font-size: 3.5em;
}

.fiv-size-xl {
  font-size: 4.75em;
}

.fiv {
  background-image: url("../icons/inc.svg"); // default icon
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1em;
  position: relative;
  width: 0.72em;

  &::before {
    content: "\00a0";
  }

  &.fiv-icon-7z {
    background-image: url("../icons/7z.svg");
  }

  &.fiv-icon-blank {
    background-image: url("../icons/blank.svg");
  }

  &.fiv-icon-bmp {
    background-image: url("../icons/bmp.svg");
  }

  &.fiv-icon-bz2 {
    background-image: url("../icons/bz2.svg");
  }

  &.fiv-icon-chm {
    background-image: url("../icons/chm.svg");
  }

  &.fiv-icon-csv {
    background-image: url("../icons/csv.svg");
  }

  &.fiv-icon-doc {
    background-image: url("../icons/doc.svg");
  }

  &.fiv-icon-docx {
    background-image: url("../icons/docx.svg");
  }

  &.fiv-icon-eps {
    background-image: url("../icons/eps.svg");
  }

  &.fiv-icon-epub {
    background-image: url("../icons/epub.svg");
  }

  &.fiv-icon-fb2 {
    background-image: url("../icons/fb2.svg");
  }

  &.fiv-icon-gif {
    background-image: url("../icons/gif.svg");
  }

  &.fiv-icon-gz {
    background-image: url("../icons/gz.svg");
  }

  &.fiv-icon-hlp {
    background-image: url("../icons/hlp.svg");
  }

  &.fiv-icon-ibooks {
    background-image: url("../icons/ibooks.svg");
  }

  &.fiv-icon-inc {
    background-image: url("../icons/inc.svg");
  }

  &.fiv-icon-jpeg {
    background-image: url("../icons/jpeg.svg");
  }

  &.fiv-icon-jpg {
    background-image: url("../icons/jpg.svg");
  }

  &.fiv-icon-mobi {
    background-image: url("../icons/mobi.svg");
  }

  &.fiv-icon-ods {
    background-image: url("../icons/ods.svg");
  }

  &.fiv-icon-odt {
    background-image: url("../icons/odt.svg");
  }

  &.fiv-icon-pdf {
    background-image: url("../icons/pdf.svg");
  }

  &.fiv-icon-png {
    background-image: url("../icons/png.svg");
  }

  &.fiv-icon-pps {
    background-image: url("../icons/pps.svg");
  }

  &.fiv-icon-ppsx {
    background-image: url("../icons/ppsx.svg");
  }

  &.fiv-icon-ps {
    background-image: url("../icons/ps.svg");
  }

  &.fiv-icon-rar {
    background-image: url("../icons/rar.svg");
  }

  &.fiv-icon-rtf {
    background-image: url("../icons/rtf.svg");
  }

  &.fiv-icon-svg {
    background-image: url("../icons/svg.svg");
  }

  &.fiv-icon-tar {
    background-image: url("../icons/tar.svg");
  }

  &.fiv-icon-tex {
    background-image: url("../icons/tex.svg");
  }

  &.fiv-icon-tgz {
    background-image: url("../icons/tgz.svg");
  }

  &.fiv-icon-tiff {
    background-image: url("../icons/tiff.svg");
  }

  &.fiv-icon-tif {
    background-image: url("../icons/tif.svg");
  }

  &.fiv-icon-txt {
    background-image: url("../icons/txt.svg");
  }

  &.fiv-icon-webp {
    background-image: url("../icons/webp.svg");
  }

  &.fiv-icon-xls {
    background-image: url("../icons/xls.svg");
  }

  &.fiv-icon-xlsx {
    background-image: url("../icons/xlsx.svg");
  }

  &.fiv-icon-xml {
    background-image: url("../icons/xml.svg");
  }

  &.fiv-icon-zip {
    background-image: url("../icons/zip.svg");
  }

  &.fiv-icon-z {
    background-image: url("../icons/z.svg");
  }
}
