/*
** Стандартная страница
*/

p {
  word-wrap: break-word;
  hyphens: auto;
}

// Секция заголовка статьи
.page-header {
  color: #2c2c2c;

  h1 {
    // font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
    font-family: Oswald, "Helvetica Neue Bold Condensed", "Helvetica Condensed",
      "Roboto Condensed", Arial, sans-serif;
    font-size: 3rem;
    font-weight: 700;

    @include breakpoint(small down) {
      font-size: 2rem;
    }

    @include breakpoint(medium only) {
      font-size: 2.5rem;
    }
  }
  // h1 с последующим подзаголовком
  .with-subtitle {
    margin-bottom: 0;
  }
  // подзаголовок в карточке на индексной странице
  .card-subtitle {
    font-family: Oswald, "Helvetica Neue Bold Condensed", "Helvetica Condensed",
      "Roboto Condensed", Arial, sans-serif;
    font-size: 0.9rem;
    font-style: italic;
    line-height: 0.8;
    margin-bottom: 0.5em;

    @include breakpoint(large up) {
      font-size: 1.5rem;
    }
  }
  // подзаголовок
  .subtitle {
    // font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
    font-family: Oswald, "Helvetica Neue Bold Condensed", "Helvetica Condensed",
      "Roboto Condensed", Arial, sans-serif;
    font-size: 2rem;
    font-style: italic;
    margin-top: -0.5em;

    @include breakpoint(small down) {
      font-size: 1.1rem;
    }
  }
  // Введение к статье
  .intro {
    color: $medium-dark-gray;
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 0 10px;

    @include breakpoint(medium up) {
      font-size: 1.4rem;
      margin: 0 0 30px;
    }

    p {
      line-height: 1.3;
      text-align: justify;
    }
  }
}

// Секция основного контента статьи
.page-content {
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $header-gray;
    // font-family: "Playfair Display", Georgia, "Times New Roman", Times, serif;
    font-family: Oswald, "Helvetica Neue Bold Condensed", "Helvetica Condensed",
      "Roboto Condensed", Arial, sans-serif;
  }
}
