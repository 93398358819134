.post-advert {
  time {
    background-color: lightslategray;
    color: $white;
    padding: 0.1rem 1rem;
  }
}

.advert-date {
  font-style: italic;
}
