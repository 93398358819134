/*
** Цвета используемые сайтом
*/

/// @type Color
$header-gray: #2c2c2c !default;
/// @type Color
$very-dark-gray: #333 !default;
/// @type Color
$medium-dark-gray: #4d4d4d !default;
/// @type Color
$text-dark-gray: #484848 !default;
/// @type Color
$dark-blue: #0d2d62 !default;

/// @type Color
$menu-active: cornflowerblue;
/// @type Color
$menu-bg-hover: #3676e8;
/// @type Color
$menu-text-hover: $white;
/// @type Color
$menu-text: $primary-color;

/// @type Color
$footer-bg: #3a4149;

/// @type Color
$hp-news-hdr: #b80924;

/// Get color from foundation-palette
///
/// @param {key} color key from foundation-palette
///
/// @returns {Color} color from foundation-palette
@function get-color($key) {
  @if map-has-key($foundation-palette, $key) {
    @return map-get($foundation-palette, $key);
  } @else {
    @error 'given $key is not available in $foundation-palette';
  }
}
