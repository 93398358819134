/*
** Стили для шапки сайта
*/

// колонка переключателя языка в title-bar
.title-bar-lang {
  margin-left: auto;
}

.top-bar {
  padding: 0;
}

// ---------------------------------------------------------------------
// Меню быстрых ссылок
// ---------------------------------------------------------------------
.q-link {
  background: $very-dark-gray;
  min-height: 20px;
  padding: 4px 0;

  ul {
    background: inherit;
    list-style: none;
    // margin-bottom: 0;
    margin: 0 0 0 1rem;

    li {
      float: left;
      font-size: x-small !important;
      line-height: 1.2rem;
      padding: 0 1rem;
      position: relative;
    }

    a {
      border-radius: 0 1.2em;
      color: white;
      display: block;
      margin: 0 -1rem;
      padding: 0 1rem;
      text-transform: uppercase;
      transition: all 0.4s ease;

      &:hover {
        background-color: #00afd4;
      }
    }

    &.lang {
      background-color:#000;
      border: 1px solid #666;
      border-radius: 0 1em;
      overflow: hidden;
      margin-left: auto;
      margin-right: 1rem;

      a {
        font-weight: 700;
        color: whitesmoke;

        &:hover {
          background-color: #2E8B57;
          text-decoration: underline dotted;
        }
      }
    }
  }
}

.header {
  // ---------------------------------------------------------------------
  // Брэнд (логотип и название организации)
  // ---------------------------------------------------------------------
  .brand {
    align-items: center;      /* align item vertically */
    background-color: #e6e6e6;
    display: flex;
    justify-content: center;  /* align item horizontally */
    padding: 0.2rem 0;

    img {
      max-height: 4em;

      @include breakpoint(large) {
        max-height: 5em;
      }
    }

    p {
      color: $dark-blue;
      font-size: large;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 0;
      text-align: center;

      @include breakpoint(medium up) {
        font-size: larger;
      }

      @include breakpoint(large) {
        font-size: x-large;
      }
    }

    .ras {
      color: $medium-dark-gray;
      font-size: smaller;
      // font-style: italic;
      text-transform: uppercase;
    }
  }
}
// ---------------------------------------------------------------------
// Главное меню сайта
// ---------------------------------------------------------------------

.top-menu {
  // Стрелочка вниз для dropdown меню
  .arr {
    display: none;    // не показываем на смартфонах
    width: 7px;
    height: 7px;
    margin-top: -6px;
    margin-left: 10px;
    content: "";
    transform: rotate(-45deg);
    border-bottom: 1px solid #999;
    border-left: 1px solid #999;

    @include breakpoint(medium up) {
      display: block; // а на широких экранах показываем
    }
  }

  // Выравниваем меню по центру на компах
  @include breakpoint(medium up) {
    justify-content: center;
  }

  // Оформление горизонтального меню
  &.dropdown.menu.medium-horizontal > li > a {
    align-items: center;
    display: flex;
    font-weight: 700;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    text-transform: uppercase;

    @include breakpoint(medium only) {
      font-size: 15px;
    }
  }

  // Анимация гориз. меню
  &.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #80cfb5;
    content: "";
    opacity: 0;
    transition: height 0.3s, opacity 0.3s, transform 0.3s;
    transform: translateY(-0.5rem);
    border: none;
    margin-top: -6px;
  }
  // Анимация гориз. меню
  &.dropdown.menu.medium-horizontal > li.is-active > a::after {
    /* Этот блок должен быть после
    li.is-dropdown-submenu-parent > a::after
    иначе анимация подчеркиванием в топ-меню не работает */
    height: 0.4rem;
    opacity: 1;
    transform: translateY(0px); /* stylelint-disable-line */
  }

  // Цвет пунктов субменю при наведении мыши
  .submenu a:hover {/* stylelint-disable-line */
    color: $menu-text-hover;
  }

  // Ширна блока субменю
  .is-dropdown-submenu {
    min-width: 16em;
  }

  .menu {
    a { /* stylelint-disable-line */
      // line-height: 0.9;
      padding: .5rem 1rem;

      @include breakpoint(medium only) {
        font-size: 14px;
      }
    }
    .is-active > a {/* stylelint-disable-line */
      background-color: $menu-active;

      &:hover {/* stylelint-disable-line */
        background-color: $menu-bg-hover;
      }
    }
    .is-dropdown-submenu-item > a:hover {/* stylelint-disable-line */
      background-color: $menu-bg-hover;
    }
  }

  /* Цвет стрелок в субменю при наведении мыши */
  .submenu.menu li.opens-right.is-active > a::after {/* stylelint-disable-line */
    border-left-color: $menu-text-hover;
  }

  /* Цвет текста и фона меню при наведении мыши (смартфоны) */
  &.accordion-menu li > a:hover {/* stylelint-disable-line */
    color: $menu-text-hover;
    background-color: $menu-bg-hover;
  }

  /* Цвет стрелок в меню при наведении мыши (смартфоны) */
  &.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a:hover::after {/* stylelint-disable-line */
    border-top-color: $menu-text-hover;
  }
}
