/*
** Вспомогательные селекторы
*/

/* Опции горизонтального выравнивания в таблицах */
.htCenter { /* stylelint-disable-line */
  text-align: center;
}

.htJustify { /* stylelint-disable-line */
  text-align: justify;
}

.htRight { /* stylelint-disable-line */
  text-align: right;
}

.htLeft { /* stylelint-disable-line */
  text-align: left;
}

/* Опции вертикального выравнивания в таблицах */
.htTop { /* stylelint-disable-line */
  vertical-align: top;
}

.htMiddle { /* stylelint-disable-line */
  vertical-align: middle;
}

.htBottom { /* stylelint-disable-line */
  vertical-align: bottom;
}

/* Оформление архивов новостей, публикаций и т.п. */
.arch {
  font-family: Oswald, "Helvetica Neue Bold Condensed", "Helvetica Condensed",
    "Roboto Condensed", Arial, sans-serif;
  font-weight: 600;
}

.arch-year {
  font-size: 120%;

  @include breakpoint(medium only) {
    font-size: 95%;
  }
}

.arch-month {
  font-size: 110%;

  @include breakpoint(medium only) {
    font-size: 85%;
  }
}
