.card-struct {
  li::marker {
    color: $dark-blue;
  }

  .subitem {
    color: $dark-blue;

    &:hover {
      color: $menu-active;
      text-decoration: underline;
    }
  }

  h2 {
    a {
      color: $dark-blue;
      font-size: medium;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      transition: all .3s ease;

      &:hover {
        color: $very-dark-gray;
      }
    }

    @include breakpoint(medium only) {
      font-size: medium;
    }

    @include breakpoint(large up) {
      font-size: large;
    }
  }

  .card-divider {
    padding: 0.3rem 1rem;
    background-color:lightblue;
    font-style: italic;
  }
}

.dept-contacts {
  background-color: get-color(primary);
  color: $white;
  padding: 0 .5rem;

  > a { //  stylelint-disable-line
    color: inherit;
  }
}

.subdvsn-card {
  h3 {
    > a { //  stylelint-disable-line
      // color: $dark-blue;
      font-size: medium;
      // font-weight: 600;
      text-decoration: none;
      transition: all .3s ease;

      &:hover {
        color: $very-dark-gray;
      }

      @include breakpoint(medium only) {
        font-size: large;
      }

      @include breakpoint(large up) {
        font-size: x-large;
      }
    }
  }

  .media-object {
    padding-top: .3rem;
  }
}
